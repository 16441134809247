import React, {useEffect, useState} from 'react';
import './App.css';
import backgroundImg from "./background.jpg"
import macScreenA from "./mac_screen_1.jpg"
import macScreenB from "./mac_screen_2.jpg"
import firebase from "firebase"
import { useTranslation } from 'react-i18next';




function App() {

  const { t, i18n } = useTranslation();
  
  const [language, setLanguage] = useState(t("language.1") === "en" ? "en" : "de")

  useEffect(() => {
    console.log(t("language.1"))
    document.title = language === "en" ? "Change NAT-Type with VPN" : "NAT Typ mit VPN ändern"

    // Your web app's Firebase configuration
    const firebaseConfig = {
      apiKey: "AIzaSyAJ25Qnn9EPhXn2tL1cqczjglqV2z3B978",
      authDomain: "changenattyp.firebaseapp.com",
      databaseURL: "https://changenattyp.firebaseio.com",
      projectId: "changenattyp",
      storageBucket: "changenattyp.appspot.com",
      messagingSenderId: "900231001303",
      appId: "1:900231001303:web:4cac73c2f02eb40bbf6c92",
      measurementId: "G-V9SB0WX022"
    }
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);

    // more at https://www.youtube.com/watch?v=iVHRy_uVtm0&t=197s

    // eslint-disable-next-line
  }, [])


  const onGetVPNClick = () => {
    window.open("https://www.expressvpn.com/de", '_blank')
    getToClick("VPN")
  }

  const getToClick = (item) => {
    const analytics = firebase.analytics();
    analytics.logEvent("getTo_item", {product: item})
  }

  const onChangeLangClick = (lang) => {
    setLanguage(lang)
    i18n.changeLanguage(lang);
  }

  return (
    <div className="App">
      <div className="header-container">
        <img src={backgroundImg} alt="Zeigt ein PS4 Controller"/>
        <div className="header-wrapper">
          {/* <h1>NAT TYP ÄNDERN</h1> */}
          <h1>{t("Header1.1")}</h1>
          {/* <h2>Ohne Portfreigabe. So gehts:</h2> */}
          <h2>{t("Header2.1")}</h2>
        </div>
      </div>

      <div className="wrapper">
        <p className="text-1">{t("Intro1.1")}</p>
        <p className="text-2">{t("Intro2.1")}</p>
        <p className="text-3">{t("Quest1.1")}</p>

        <div className="aufzählung">
          <span>
            <div className="point"></div>
            <p>{t("PC.1")}</p>
          </span>
          <span className="aufzählung">
            <div className="point"></div>
            <p><a href="https://www.amazon.de/UGREEN-Ethernet-Netzwerkadapter-Ultrabook-Aluminum/dp/B07M91X2NW/ref=sr_1_6?__mk_de_DE=ÅMÅŽÕÑ&keywords=lan+usb&qid=1576619880&sr=8-6" target="_blank" rel="noopener noreferrer" onClick={() => getToClick("USB zu LAN")}>{t("USB.1")}</a></p>
          </span>
          <span className="aufzählung">
            <div className="point"></div>
            <p><a href="https://www.amazon.de/Ethernet-Adapter-Gigabit-1000Mbps-Netzwerkadapterkabel/dp/B077KXY71Q/ref=sr_1_3?__mk_de_DE=ÅMÅŽÕÑ&keywords=lan+usbc&qid=1576619928&sr=8-3" target="_blank" rel="noopener noreferrer" onClick={() => getToClick("USB C zu LAN")}>{t("USB C.1")}</a></p>
          </span>
          <span className="aufzählung">
            <div className="point"></div>
            <p><a href="https://www.amazon.de/UGREEN-Netzwerkkabel-Verlegekabel-Patchpannel-Patchfelder/dp/B00QV1F19C/ref=sr_1_4?__mk_de_DE=ÅMÅŽÕÑ&crid=260RUHYT86N6O&keywords=lan+kabel&qid=1576619829&sprefix=lan+klage%2Caps%2C168&sr=8-4" target="_blank" rel="noopener noreferrer" onClick={() => getToClick("LAN Kabel")}>{t("Cable.1")}</a></p>
          </span>
          <span className="aufzählung">
            <div className="point"></div>
            <p>VPN</p>
          </span>
        </div>

        <p className="text-4">{t("Which.1")}</p>
        <p className="text-5">{t("Which text 1.1")}<br/><a href="https://www.expressvpn.com/de" target="_blank" rel="noopener noreferrer" onClick={() => getToClick("VPN")}>ExpressVPN</a>{t("Which text 2.1")}</p>

        <p className="text-6">{t("What.1")}</p>
        <div className="aufzählung-2">
          <span>
            <div className="point"></div>
            <p>{t("Hide.1")}</p>
          </span>
          <span className="aufzählung">
            <div className="point"></div>
            <p>{t("Encrypt.1")}</p>
          </span>
          <span className="aufzählung">
            <div className="point"></div>
            <p>{t("Stream.1")}</p>
          </span>
        </div>

        <button className="zumVPN" onClick={onGetVPNClick}>{t("Get to VPN.1")}</button>

        <p className="text-7">{t("How.1")}</p> 
        <p className="text-8">{t("1 Plug.1")}</p>
        <p className="text-9">{t("2 Establish.1")}</p>
        <p className="text-10">MAC OS (EXPRESS VPN)</p>
        <iframe title="macFrame" width="560" height="315" src="https://www.youtube.com/embed/leoIc14hYCs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p className="text-11">WINDOWS (EXPRESS VPN)</p>
        <iframe title="windowsFrame" width="560" height="315" src="https://www.youtube.com/embed/6JeVTAYLjBg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p className="text-12">{t("3 Internet sharing.1")}</p>

        <p className="text-13">MAC OS</p>
        <img className="img-mac-1" src={macScreenA} alt="Screenshot von Sytstemeinstellung vom Mac"/>
        <p className="text-14">{t("1 Open settings.1")}</p>
        <img className="img-mac-2" src={macScreenB} alt="Screenshot von Freigaben vom Mac"/>
        <p className="text-15">{t("2 Click Internet Sharing.1")}</p>
        <p className="text-16">{t("3 Activate.1")}</p>
        <p className="text-17">WINDOWS</p>
        <p className="text-18">{t("Win 1.1")}</p>
        <p className="text-19">{t("Win 2.1")}</p>
        <p className="text-20">{t("Win 3.1")}</p>
        <p className="text-21">{t("Win 4.1")}</p>

        <p className="last">{t("Outro.1")}</p>

        <div className="endContainer">
          <a className="toMainLink" href="https://honk.international/">von HONK International</a>
          <div className="langContainer">
            {console.log("->", t("language.1"))}
            <button onClick={() => onChangeLangClick("en")} style={{fontWeight: language === "en" ? "800" : "400"}}>EN</button>
            <button onClick={() => onChangeLangClick("de")} style={{fontWeight: language === "de" ? "800" : "400"}}>DE</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
